import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuardService } from './services/auth-guard.service';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'dashboard-tech',
    pathMatch: 'full'
  },
  {
    path: 'login',
    loadChildren: () => import('./pages/login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'dashboard-tech',
    loadChildren: () => import('./pages/dashboard-tech/dashboard-tech.module').then( m => m.DashboardTechPageModule),
    canActivate: [AuthGuardService]
  },
  {
    path: 'cita/:id',
    loadChildren: () => import('./pages/cita/cita.module').then( m => m.CitaPageModule),
    canActivate: [AuthGuardService]
  },
  {
    path: 'referidos',
    loadChildren: () => import('./pages/referidos/referidos.module').then( m => m.ReferidosPageModule),
    canActivate: [AuthGuardService]
  },
  {
    path: 'add-referido',
    loadChildren: () => import('./pages/add-referido/add-referido.module').then( m => m.AddReferidoPageModule),
    canActivate: [AuthGuardService]
  },
  {
    path: 'cotizaciones',
    loadChildren: () => import('./pages/cotizaciones/cotizaciones.module').then( m => m.CotizacionesPageModule),
    canActivate: [AuthGuardService]
  },
  {
    path: 'perfil',
    loadChildren: () => import('./pages/perfil/perfil.module').then( m => m.PerfilPageModule),
    canActivate: [AuthGuardService]
  },
  {
    path: 'add-cotizacion',
    loadChildren: () => import('./pages/add-cotizacion/add-cotizacion.module').then( m => m.AddCotizacionPageModule),
    canActivate: [AuthGuardService]
  },
  {
    path: 'bitacora/:id',
    loadChildren: () => import('./pages/bitacora/bitacora.module').then( m => m.BitacoraPageModule),
    canActivate: [AuthGuardService]
  },
  {
    path: 'detail-cotizacion/:id',
    loadChildren: () => import('./pages/detail-cotizacion/detail-cotizacion.module').then( m => m.DetailCotizacionPageModule),
    canActivate: [AuthGuardService]
  },
  {
    path: 'modal-clientes',
    loadChildren: () => import('./pages/modal-clientes/modal-clientes.module').then( m => m.ModalClientesPageModule),
    canActivate: [AuthGuardService]
  },
  {
    path: 'modal-productos',
    loadChildren: () => import('./pages/modal-productos/modal-productos.module').then( m => m.ModalProductosPageModule),
    canActivate: [AuthGuardService]
  },
  {
    path: 'pdf-viewer',
    loadChildren: () => import('./modals/pdf-viewer/pdf-viewer.module').then( m => m.PdfViewerPageModule),
    canActivate: [AuthGuardService]
  },
  {
    path: 'clientes',
    loadChildren: () => import('./pages/clientes/clientes.module').then( m => m.ClientesPageModule),
    canActivate: [AuthGuardService]
  },
  {
    path: 'add-cliente',
    loadChildren: () => import('./pages/add-cliente/add-cliente.module').then( m => m.AddClientePageModule),
    canActivate: [AuthGuardService]
  },
  {
    path: 'edit-cliente/:id',
    loadChildren: () => import('./pages/edit-cliente/edit-cliente.module').then( m => m.EditClientePageModule),
    canActivate: [AuthGuardService]
  },
  {
    path: 'chat/:id',
    loadChildren: () => import('./pages/chat/chat.module').then( m => m.ChatPageModule),
    canActivate: [AuthGuardService]
  },
  {
    path: 'miservicios',
    loadChildren: () => import('./pages/miservicios/miservicios.module').then( m => m.MiserviciosPageModule),
    canActivate: [AuthGuardService]
  },
  {
    path: 'servicios',
    loadChildren: () => import('./pages/servicios/servicios.module').then( m => m.ServiciosPageModule),
    canActivate: [AuthGuardService]
  },
  {
    path: 'mis-citas',
    loadChildren: () => import('./pages/mis-citas/mis-citas.module').then( m => m.MisCitasPageModule),
    canActivate: [AuthGuardService]
  },
  {
    path: 'chat-users',
    loadChildren: () => import('./modals/chat-users/chat-users.module').then( m => m.ChatUsersPageModule)
  },
  {
    path: 'notificaciones',
    loadChildren: () => import('./modals/notificaciones/notificaciones.module').then( m => m.NotificacionesPageModule)
  },
  {
    path: 'bitacora-users',
    loadChildren: () => import('./modals/bitacora-users/bitacora-users.module').then( m => m.BitacoraUsersPageModule)
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
