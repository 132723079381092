import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { NotificacionesService } from 'src/app/services/notificaciones.service';

@Component({
  selector: 'app-notificaciones',
  templateUrl: './notificaciones.page.html',
  styleUrls: ['./notificaciones.page.scss'],
})
export class NotificacionesPage implements OnInit {
  chats = [];
  tracings = [];
  systems = [];
  loadChat = false;
  loadTracing = false;
  loadSystem = false;
  constructor(
    private modalCtrl: ModalController,
    private notService: NotificacionesService,
    private router: Router
  ) { }

  ngOnInit() {
    this.getChats();
    this.getTracings();
    this.getSystems();
  }

  dismiss() {
    // using the injected ModalController this page
    // can "dismiss" itself and optionally pass back data
    this.modalCtrl.dismiss(() => {
    });
  }

  getTracings() {
    this.loadTracing = true;
    this.notService.msnTracings().subscribe(data => {
      this.tracings = data['data'];
      this.loadTracing = false;
    }, error => {
      this.loadTracing = false;
    });
  }

  getChats() {
    this.loadChat = true;
    this.notService.msnChats().subscribe(data => {
      this.chats = data['data'];
      this.loadChat = false;
    }, error => {
      this.loadChat = false;
    });
  }

  getSystems() {
    this.loadSystem = true;
    this.notService.msnSystems().subscribe(data => {
      this.systems = data['data'];
      this.loadSystem = false;
    }, error => {
      this.loadSystem = false;
    });
  }

  chat(id) {
    this.router.navigateByUrl('chat/' + id);
    this.dismiss();
  }

  bitacora(id) {
    this.router.navigateByUrl('bitacora/' + id);
    this.dismiss();
  }

  fill(number, len) {
    return "0".repeat(len - number.toString().length) + number.toString();
  }
}
