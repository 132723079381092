import { Component, OnInit } from '@angular/core';

import {
  Platform,
  ModalController,
  NavController,
  AlertController,
  MenuController,
  LoadingController,
} from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { Router } from '@angular/router';
import { ToastService } from './services/toast.service';
import { AuthService } from './services/auth.service';
import { AngularFireDatabase } from '@angular/fire/database';
import { NotificacionesPage } from './modals/notificaciones/notificaciones.page';
import { LocalNotifications } from '@ionic-native/local-notifications/ngx';
import { BackgroundMode } from '@ionic-native/background-mode/ngx';
import { NotificacionesService } from './services/notificaciones.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit {
  contChat = 0;
  arrayNotChat = [];
  contBitacora = 0;
  arrayNotBitacora = [];
  contSystem = 0;
  arrayNotSystem = [];
  cargandoMenu = false;
  public selectedIndex = 0;
  public pages = [];

  pages_temp = [
    {
      title: 'Inicio',
      icon: 'home',
      url: '/dashboard-tech',
    },
    {
      title: 'Mis Servicios',
      icon: 'calendar',
      url: '/miservicios',
      name: 'tecnico',
    },
    {
      title: 'Mis Citas',
      icon: 'time',
      url: '/mis-citas',
      name: 'tecnico',
    },
    {
      title: 'Cotizaciones',
      icon: 'calculator',
      url: '/cotizaciones',
      name: 'cotizacion',
    },
    {
      title: 'Servicios',
      icon: 'calendar',
      url: '/servicios',
      name: 'servicio',
    },
    {
      title: 'Referidos',
      icon: 'call',
      url: '/referidos',
      name: 'referido',
    },
    {
      title: 'Clientes',
      icon: 'people',
      url: '/clientes',
      name: 'cliente',
    },
  ];
  clickSub: any;

  constructor(
    private router: Router,
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private modalCtrl: ModalController,
    private navCtrl: NavController,
    private alertController: AlertController,
    private toastServ: ToastService,
    private loadingController: LoadingController,
    private auth: AuthService,
    private afs: AngularFireDatabase,
    private menuC: MenuController,
    private localNot: LocalNotifications,
    private notServ: NotificacionesService,
    private backgroundMode: BackgroundMode
  ) {
    this.backgroundMode.enable();
    this.initializeApp();
  }

  initializeApp() {
    this.platform
      .ready()
      .then(() => {
        document.addEventListener('changeUser', (e) => {
          this.cargarMenu();
          this.setNotChat();
          this.setNotBitacora();
          this.setNotSystem();
          this.getPerfil();
          this.getCompany();
        });
        document.addEventListener('notificaciones', (e) => {
          this.mostrarNotificaciones();
        });

        this.opcionesBackButton();

        this.statusBar.styleDefault();
        this.splashScreen.hide();
        if (localStorage.getItem('idUser')) {
          this.cargarMenu();
          this.setNotChat();
          this.setNotBitacora();
          this.setNotSystem();
          this.getPerfil();
          this.getCompany();
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  ngOnInit() {
    
  }

  doLogout() {
    localStorage.clear();
    this.navCtrl.navigateRoot('/login');
    this.menuC.close();
    this.menuC.swipeGesture(false);
  }

  showExitConfirm() {
    if (this.router.url === '/login') {
      navigator['app'].exitApp();
    } else {
      this.alertController
        .create({
          header: 'Salir',
          message: '¿Está seguro que desea salir de la aplicación?',
          backdropDismiss: false,
          buttons: [
            {
              text: 'Cancelar',
              role: 'cancel',
              handler: () => {
                console.log('Application exit prevented!');
              },
            },
            {
              text: 'Salir',
              handler: () => {
                navigator['app'].exitApp();
              },
            },
          ],
        })
        .then((alert) => {
          alert.present();
        });
    }
  }

  cargarMenu() {
    this.cargandoMenu = true;
    this.pages = [];
    const list = JSON.parse(localStorage.getItem('permisos'));
    // console.log(list);
    this.pages_temp.forEach((element, i) => {
      if (i === 0) {
        this.pages.push(element);
      } else {
        if (list.some((permiso) => element.name === permiso['name'])) {
          this.pages.push(element);
        }
      }
    });
    this.cargandoMenu = false;
    this.goHome();
  }

  goHome() {
    this.auth.perfil().subscribe(
      (data) => {
        localStorage.setItem('idUser', data['id']);
      },
      (error) => {
        this.toastServ.error('No se pudo cargar la información de usuario');
      }
    );
  }

  async mostrarNotificaciones() {
    const modal = await this.modalCtrl.create({
      component: NotificacionesPage,
      // componentProps: { value: 123 },
    });

    await modal.present();
  }

  async setNotChat() {
    this.notServ.newChats().subscribe((data) => {
      this.arrayNotChat = data['data'];
      if (this.arrayNotChat.length > 0) {
        this.arrayNotChat.forEach((element) => {
          this.notificacionChat(element);
        });
      }
    });
    const userId = localStorage.getItem('idUser');
    setTimeout(async () => {
      const serv = await this.afs.database
        .ref('notify/service/' + userId)
        .once('value');
      // console.log(serv.val());
      this.contChat = serv.val();
      this.updateNotChat();
    }, 3000);
  }

  async updateNotChat() {
    const userId = localStorage.getItem('idUser');
    const serv = await this.afs.database.ref('notify/service/' + userId);
    serv.on('value', (snapshot) => {
      if (snapshot.val() > this.contChat) {
        this.notServ.newChats().subscribe(
          (data) => {
            this.arrayNotChat = data['data'];
            // console.log(data['data']);
            this.contChat = snapshot.val();
            // console.log(this.contChat);
            this.arrayNotChat.forEach((element) => {
              this.notificacionChat(element);
            });
          },
          (error) => {
            console.log(error);
          }
        );
      }
    });
  }

  async notificacionChat(data: any[]) {
    let clickSub = this.localNot.on('click').subscribe(() => {
      this.router.navigateByUrl('chat/' + data['service_id']);
      clickSub.unsubscribe();
    });
    this.localNot.schedule({
      id: data['id'],
      title:
        'Tienes un nuevo mensaje en el servicio \n' +
        this.fill(data['service_id'], 6),
      text: data['msn'],
    });
  }
  async setNotBitacora() {
    this.notServ.newTracings().subscribe((data) => {
      this.arrayNotBitacora = data['data'];
      if (this.arrayNotBitacora.length > 0) {
        this.arrayNotBitacora.forEach((element) => {
          this.notificacionBitacora(element);
        });
      }
    });
    const userId = localStorage.getItem('idUser');
    setTimeout(async () => {
      const serv = await this.afs.database
        .ref('notify/quotation/' + userId)
        .once('value');
      // console.log(serv.val());
      this.contBitacora = serv.val();
      this.updateNotBitacora();
    }, 3000);
  }

  async updateNotBitacora() {
    const userId = localStorage.getItem('idUser');
    const serv = await this.afs.database.ref('notify/quotation/' + userId);
    serv.on('value', (snapshot) => {
      if (snapshot.val() > this.contBitacora) {
        this.notServ.newTracings().subscribe(
          (data) => {
            this.arrayNotBitacora = data['data'];
            // console.log(data['data']);
            this.contBitacora = snapshot.val();
            // console.log(this.contBitacora);
            this.arrayNotBitacora.forEach((element) => {
              this.notificacionBitacora(element);
            });
          },
          (error) => {
            console.log(error);
          }
        );
      }
    });
  }

  async notificacionBitacora(data: any[]) {
    let clickSub = this.localNot.on('click').subscribe(() => {
      this.router.navigateByUrl('bitacora/' + data['quotation_id']);
      clickSub.unsubscribe();
    });
    this.localNot.schedule({
      id: data['id'],
      title:
        'Tienes un nuevo mensaje en la cotización \n' +
        this.fill(data['quotation_id'], 6),
      text: data['msn'],
    });
  }
  async setNotSystem() {
    this.notServ.newSystems().subscribe((data) => {
      this.arrayNotSystem = data['data'];
      if (this.arrayNotSystem.length > 0) {
        this.arrayNotSystem.forEach((element) => {
          this.notificacionSystem(element);
        });
      }
    });
    const userId = localStorage.getItem('idUser');
    setTimeout(async () => {
      const serv = await this.afs.database
        .ref('notify/system/' + userId)
        .once('value');
      // console.log(serv.val());
      this.contSystem = serv.val();
      this.updateNotSystem();
    }, 3000);
  }

  async updateNotSystem() {
    const userId = localStorage.getItem('idUser');
    const serv = await this.afs.database.ref('notify/system/' + userId);

    serv.on('value', (snapshot) => {
      if (snapshot.val() > this.contSystem) {
        this.notServ.newSystems().subscribe(
          (data) => {
            this.arrayNotSystem = data['data'];
            // console.log(data['data']);
            this.contSystem = snapshot.val();
            // console.log(this.contSystem);
            this.arrayNotSystem.forEach((element) => {
              this.notificacionSystem(element);
            });
          },
          (error) => {
            console.log(error);
          }
        );
      }
    });
  }

  async notificacionSystem(data: any[]) {
    let clickSub = this.localNot.on('click').subscribe(() => {
      this.mostrarNotificaciones();
      clickSub.unsubscribe();
    });
    this.localNot.schedule({
      id: data['id'],
      text: data['msn'],
    });
  }

  fill(number, len) {
    return '0'.repeat(len - number.toString().length) + number.toString();
  }

  opcionesBackButton() {
    this.platform.backButton.subscribeWithPriority(999, async () => {
      const modal = await this.modalCtrl.getTop();
      const loading = await this.loadingController.getTop();
      if (modal) {
        this.modalCtrl.dismiss();
        return;
      } else {
        if (
          this.router.url === '/dashboard-tech' ||
          this.router.url === '/referidos' ||
          this.router.url === '/clientes' ||
          this.router.url === '/cotizaciones' ||
          this.router.url === '/servicios' ||
          this.router.url === '/mis-citas' ||
          this.router.url === '/login' ||
          this.router.url === '/miservicios'
        ) {
          this.showExitConfirm();
        } else {
          if (loading) {
            console.log('loading on top');
          } else {
            this.navCtrl.back();
          }
        }
      }
    });
  }

  getPerfil() {
    this.auth.perfil().subscribe(data => {
      localStorage.setItem('currentUser', JSON.stringify(data));
    }, error => {
      console.log(error);
    });
  }

  userName() {
    let user = {};
    if (localStorage.getItem('currentUser')) {
      user = JSON.parse(localStorage.getItem('currentUser'));
    } else {
      user = '';
    }
    return user['name'];
  }

  getCompany() {
    this.auth.company().subscribe(data => {
      localStorage.setItem('company', JSON.stringify(data));
    });
  }
}

