// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
};
export const apiURL = 'https://sistema.telimpie.com/api/';

// export const firebaseConfig = {
//   apiKey: 'AIzaSyCf4laKMiycoXNLy6X0dVIKUczjw3lxxKQ',
//   authDomain: 'fir-tutorial-22d50.firebaseapp.com',
//   databaseURL: 'https://fir-tutorial-22d50.firebaseio.com',
//   projectId: 'fir-tutorial-22d50',
//   storageBucket: 'fir-tutorial-22d50.appspot.com',
//   messagingSenderId: '163979371082',
//   appId: '1:163979371082:web:01c5ea1d5a72e32fc4bcb3',
//   measurementId: 'G-Y17FXN2HCS',
// };

export const firebaseConfig = {
  apiKey: "AIzaSyAJbW-jf-xmaYnUy04oo0bWi0SQLQ1-J0Q",
  authDomain: "telimpie-prueba.firebaseapp.com",
  databaseURL: "https://telimpie-prueba.firebaseio.com",
  projectId: "telimpie-prueba",
  storageBucket: "telimpie-prueba.appspot.com",
  messagingSenderId: "816598833610",
  appId: "1:816598833610:web:11b1c8c99f8d56b00e9733",
  measurementId: "G-2NHYTPDJ3Y",
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
