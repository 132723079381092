import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HeaderService } from './header.service';
import { apiURL } from '../../environments/environment';
@Injectable({
  providedIn: 'root'
})
export class NotificacionesService extends HeaderService {
  constructor(
    private http: HttpClient,
  ) {
    super();
  }

  msnTracings() {
    this.headerPrivate = this.headerPrivate.set('Authorization', 'Bearer ' + localStorage.getItem('accessToken'));
    return this.http.get(apiURL + 'msntracings', {
      headers: this.headerPrivate,
    });
  }
  msnChats() {
    this.headerPrivate = this.headerPrivate.set('Authorization', 'Bearer ' + localStorage.getItem('accessToken'));
    return this.http.get(apiURL + 'msnchats', {
      headers: this.headerPrivate,
    });
  }
  msnSystems() {
    this.headerPrivate = this.headerPrivate.set('Authorization', 'Bearer ' + localStorage.getItem('accessToken'));
    return this.http.get(apiURL + 'msnsystems', {
      headers: this.headerPrivate,
    });
  }

  newChats() {
    this.headerPrivate = this.headerPrivate.set('Authorization', 'Bearer ' + localStorage.getItem('accessToken'));
    return this.http.get(apiURL + 'newchats', {
      headers: this.headerPrivate,
    });
  }
  newTracings() {
    this.headerPrivate = this.headerPrivate.set('Authorization', 'Bearer ' + localStorage.getItem('accessToken'));
    return this.http.get(apiURL + 'newtracings', {
      headers: this.headerPrivate,
    });
  }
  newSystems() {
    this.headerPrivate = this.headerPrivate.set('Authorization', 'Bearer ' + localStorage.getItem('accessToken'));
    return this.http.get(apiURL + 'newsystems', {
      headers: this.headerPrivate,
    });
  }
}
