import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HeaderService } from './header.service';
import { User } from '../models/user';
import { apiURL } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthService extends HeaderService {
  urlBase = '';
  public userLogged: User;

  constructor(private http: HttpClient) {
    super();
    this.urlBase = apiURL;
  }


  setUserLogged(
    // user: User
    ) {
    // this.userLogged = user;
    // localStorage.setItem('currentUser', JSON.stringify(this.userLogged));
    // this.http.put(
    //   this.urlBase + 'user/' + this.userLogged.id + '/android',
    //   { androidId: localStorage.getItem('fcm') },
    //   { headers: this.headerPrivate }).subscribe(resp => {
    //     console.log('llegue aqui');
    //     console.log(resp);
    //   });
  }

  getUserLogged(): User {
    return JSON.parse(localStorage.getItem('currentUser'));
  }

  login(user: any) {
    return this.http.post(this.urlBase + 'auth/login',
      user, { headers: this.headerPublic });
  }

  signup(newUser: any) {

    return this.http.post(this.urlBase + 'user/register',
      newUser, { headers: this.headerPublic });
  }

  permisos() {
    this.headerPrivate = this.headerPrivate.set('Authorization', 'Bearer ' + localStorage.getItem('accessToken'));
    this.headerPrivate = this.headerPrivate.set('Authorization', 'Bearer ' + localStorage.getItem('accessToken'));
    return this.http.get(apiURL + 'mypermissions', { headers: this.headerPrivate });
  }
  perfil() {
    this.headerPrivate = this.headerPrivate.set('Authorization', 'Bearer ' + localStorage.getItem('accessToken'));
    this.headerPrivate = this.headerPrivate.set('Authorization', 'Bearer ' + localStorage.getItem('accessToken'));
    return this.http.get(apiURL + 'auth/user', { headers: this.headerPrivate });
  }
  company() {
    this.headerPrivate = this.headerPrivate.set('Authorization', 'Bearer ' + localStorage.getItem('accessToken'));
    return this.http.get(apiURL + 'getCompany', { headers: this.headerPrivate });
  }



}
