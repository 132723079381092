import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanActivate {
  constructor(private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot): boolean {
    
    if (!this.hasUser()) {
      this.router.navigate(['login']);
      return false;
    }

    return true;
  }
  
  hasUser() {
    return localStorage.getItem('accessToken') ? true : false;
  }
}