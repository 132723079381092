import { Injectable } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class HeaderService {

  protected headerPublic: HttpHeaders = new HttpHeaders();
  protected headerPrivate: HttpHeaders = new HttpHeaders();
  protected headerPrivateMultipart: HttpHeaders = new HttpHeaders();

  constructor() {
    this.headerPublic = this.headerPublic.set('Content-Type', 'application/json');
    this.headerPublic = this.headerPublic.set('X-Requested-With', 'XMLHttpRequest');
    this.headerPrivate = this.headerPrivate.set('Content-Type', 'application/json');
    this.headerPrivate = this.headerPrivate.set('X-Requested-With', 'XMLHttpRequest');
    this.headerPrivateMultipart = this.headerPrivate.set('Content-Type', 'multipart/form-data');
    this.headerPrivateMultipart = this.headerPrivate.set('x-access-token', localStorage.getItem('accessToken'));
  }

}
