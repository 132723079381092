import { Injectable } from '@angular/core';
import { ToastController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class ToastService {

  constructor(private toastController: ToastController) { }

  async success(msg) {
    const toast = await this.toastController.create({
      message: msg,
      duration: 4000,
      color : 'success'
    });
    toast.present();
  }

  async error(msg) {
    const toast = await this.toastController.create({
      message: msg,
      duration: 4000,
      color : 'danger'
    });
    toast.present();
  }

  async show_error(msg) {
    const toast = await this.toastController.create({
      message: msg,
      color : 'danger',
      buttons: [
        {
          side: 'end',
          icon: 'close',
          role: 'cancel',
          handler: () => {
            console.log('Cancel clicked');
          }
        }
      ]
    });
    toast.present();
  }
  async error_conection() {
    const toast = await this.toastController.create({
      message: 'Error de conexión al servidor...',
      duration: 4000,
      color : 'danger'
    });
    toast.present();
  }

}
